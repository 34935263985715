(function ($) {
  var action = 'list_casinos';
  var datas = [];
  var listCasinosClass = '.js-list-casinos';
  var resetBtnsClass = '.js-reset-filter';
  var listCasinos = $(listCasinosClass);
  var appendCasinos = [];
  var defaultArgs = {};
  var btnsMore = [];
  var loaders = [];
  var clickCount = [];
  var clickTypes = [];
  var args = null;
  var sortChanged = false;

  function getDatas() {
    if (listCasinos.length) {
      
      listCasinos.each(function (i, e) {
        var list = $(e);
        args = list.data('args');
        defaultArgs[i] = Object.assign({}, args);
        datas.push({
          view: list.data('view'),
          page_posts: parseInt(list.data('page_posts')),
          total_posts: parseInt(list.data('total_posts')),
          args: Object.assign({}, args),
          ordered: !!list.data('ordered'),
        });
        appendCasinos.push(list.find('.js-append-casinos'));
        btnsMore[i] = ($(list.find('.js-casinos-loadmore')));
        loaders.push(list.find('.js-loader'));

        // const resetBtns = [].slice.call(list.querySelectorAll(this.resetBtnsClass));

        // ChangeFields.forEach(field => this.changeMore.push(field));
        // resetBtns.forEach(btn => this.resetBtns.push(btn));
      });
    }
  }

  function beforeGetCasinos(i) {
    var offset = datas[i].page_posts * clickCount[i];

    btnsMore[i].hide();
    loaders[i].show();

    if (2 * offset >= datas[i].total_posts && datas[i].total_posts > 0) {
      datas[i].args.posts_per_page = datas[i].total_posts - offset;
    }
    datas[i].args.offset = offset;

    getCasinos({
      action: action,
      data: {
        args: JSON.stringify(datas[i].args),
        view: datas[i].view,
        ordered: datas[i].ordered,
        total: datas[i].total_posts,
      }
    }, i);
  }

  function getCasinos(data, i) {
    $.ajax({
      url: vars.ajax_url,
      type: 'GET',
      dataType: 'json',
      data: data,
      success: function (data) {
        appendCasinos[i].append(data.html);
        loaders[i].hide();
        if (data.more) {
          btnsMore[i].show();
        } else {
          var cta = $(listCasinos[i]).find('.js-list-cta');
          
          if (cta.length) {
            cta.removeAttr('style');
          }
        }
      },
      fail: function (error) {
        console.log(error);
      }
    });
  }

  function onClickLoad() {
    if (btnsMore.length) {
      btnsMore.forEach(function(btn, i) {
        clickTypes.push('args');
        clickCount.push(0);

        btn.click(function () {
          clickCount[i]++;
          beforeGetCasinos(i);
        });
      });
    }
  }

  function sortList() {
    $('.js-list-sort').change(function () {
      var value = $(this).val();
      var top_list = $(this).closest('.js-top-list');
      var list = $(top_list.find('.tab-pane.active').find('.js-list-casinos'));
      var i = $.inArray(list[0], listCasinos);
      sortChanged = true;
      appendCasinos[i].empty();
      clickCount[i] = 0;

      if (value !== '') {
        datas[i].args.orderby = value;
        datas[i].args.order = 'DESC';
        
        if (value === 'title') {
          datas[i].args.order = 'ASC';
        } else if (value === 'meta_value_num') {
          datas[i].args.orderby = 'meta_value_num date';
          datas[i].args.meta_key = 'rating';
        } 
      } else {
        datas[i].args = Object.assign({}, defaultArgs[i]);
      }

      beforeGetCasinos(i);
    });

    $('.btn--filter').on('shown.bs.tab', function (event) {
      if (sortChanged) {
        var target = event.target;
        var top_list = $(target).closest('.js-top-list');
        var list = $(top_list.find('.tab-pane.active').find('.js-list-casinos'));
        var sort = top_list.find('.js-list-sort');
        var i = $.inArray(list[0], listCasinos);
        appendCasinos[i].empty();
        clickCount[i] = 0;
        sort.val('');
        datas[i].args = Object.assign({}, defaultArgs[i]);
        beforeGetCasinos(i);
      }
    });
  }

  getDatas();
  onClickLoad();
  sortList();
})(jQuery);